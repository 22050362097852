@import '_colors.scss';

@import 'global.scss';

@import './modules/search.scss';
// @import './modules/search_v2.scss';
// @import './modules/search_v3.scss';
// @import './modules/search_v4.scss';
// @import './modules/search_v8.scss';
// @import './modules/search_v9.scss';
@import './modules/custom_select.scss';
@import './modules/custom_autocomplete.scss';
@import './modules/custom_scrollbar.scss';
@import './modules/detail_gallery.scss';
@import './modules/featured_property_slider.scss';
@import './modules/polygon_map.scss';
@import './modules/accordion.scss';
@import './modules/modal.scss';
@import './modules/property_carousel_tabs.scss';
@import './modules/main_navigation.scss';
@import './modules/google_map.scss';
@import './modules/show_map.scss';

@import './form/default.scss';
@import './form/form_login.scss';

@import './property/property.scss';
@import './property/print.scss';
@import './property/saving_search_modal.scss';
@import './property/link_generator.scss';
@import './property/stored_search.scss';
@import './project/project.scss';

@import './favorites/favorites.scss';

@import './user_area/user_area.scss';
@import './user_area/form/stored_search_frequency.scss';
@import './user_area/form/send_message.scss';

@import 'pages/pages.scss';
@import 'property/no_result.scss';